import { render, staticRenderFns } from "./StoreOwnerLayout.vue?vue&type=template&id=4619b058&scoped=true"
import script from "./StoreOwnerLayout.vue?vue&type=script&lang=ts"
export * from "./StoreOwnerLayout.vue?vue&type=script&lang=ts"
import style0 from "./StoreOwnerLayout.vue?vue&type=style&index=0&id=4619b058&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4619b058",
  null
  
)

export default component.exports