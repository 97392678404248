import { NavButtonData } from '@/types/nav-buttons';
import { publicRouteNames } from '@/route-names/public';
import { storeOwnerRouteNames } from '@/route-names/store-owner';

export const advertiserNavButtons: NavButtonData[] = [
  {
    name: 'new_campaign',
    icon: '/assets/icons/rounded-plus.svg',
    route: publicRouteNames.CREATE_CAMPAIGN,
  },
  // {
  //   name: 'Notifications',
  //   icon: '/assets/icons/bell.svg',
  //   path: ''
  // },
  // {
  //   name: 'Help',
  //   icon: '/assets/icons/question-mark.svg',
  //   path: ''
  // },
]

export const storeOwnerNavButtons: NavButtonData[] = [
  // {
  //   name: 'store_campaigns',
  //   icon: '/assets/icons/campaign.svg',
  //   route: storeOwnerRouteNames.STORE_CAMPAIGNS
  // },
  {
    name: 'notifications',
    icon: '/assets/icons/bell.svg',
  },
  {
    name: 'help',
    icon: '/assets/icons/question-mark.svg',
    route: storeOwnerRouteNames.HELP
  },
]